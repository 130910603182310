$chatColor1: #fff;
$chatColor2: #4d00f4;
$chatColor3: #888;
$chatColor4: #f2f2f2;
$chatColor5: #c9b2fc;
$chatColor6: #c8ffb5;
$chatColor7: #ddd;
$chatColor8: #ff3939;
body {
  font-family: Arial, sans-serif;
  ::selection {
    background: $chatColor2;
    color: $chatColor1;
  }
}
button, svg {
  cursor: pointer;
}
.hidden {
  display: none!important;
}


.chat-plugin {
  * {
    font-size: 14px;
  }
  .chat-button {
    position: fixed;
    bottom: 10px;
    right: 10px;
    padding: 15px;
    border-radius: 50%;
    background: $chatColor2;
    cursor: pointer;
    .icon {
      padding: 6px 3px 0 3px;
      color: $chatColor1;
      font-size: 24px;
    }
    &.close {
      background: $chatColor3;
      .icon {
        padding: 1px 5px;
        font-size: 30px;
      }
    }
  }

  .chat-popup {
    position: absolute;
    bottom: 80px;
    right: 10px;
    width: 350px;
    background: $chatColor1;
    border: 2px solid $chatColor1;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 #000000b3;
    opacity: 100%;
    transition: opacity 500ms;

    &.hide {
      opacity: 0;
    }

    .header {
      padding: 15px;
      display: flex;
      justify-content: space-between;
      border-radius: 5px 5px 0 0;
      background: $chatColor2 url('logo.png') 15px 17px / 200px no-repeat;
      color: $chatColor1;

      .icon {
        display: none;
      }

      .title {
        text-transform: uppercase;
      }

      .icons {
        padding-top:2px;
        .options {
          margin-right: 10px;
          font-size: 24px;
        }

        .close {
          font-size: 20px;
          padding: 0 4px;
          border-radius: 50%;
          border: 2px solid white;
        }
      }
    }

    .chat-login-form {
      padding:15px;
      * {
        box-sizing: border-box;
      }
      input,button {
        width: 100%;
        padding:10px;
        margin-bottom: 15px;
        outline: none;
        border-radius: 5px;
        border: 1px solid $chatColor3;
        ::placeholder {
          color: $chatColor7;
        }
      }
      button {
        width: 100%;
        background: $chatColor2;
        color: $chatColor1;
        border: none;
      }

    }

    .chat-options {
      height: 160px;
      overflow: hidden;
      transition: height 500ms;
      background: $chatColor2;
      color: $chatColor1;
      &.hide {
        height: 0 !important;
      }
      label {
        div {
          padding:10px 20px 3px;
          font-size: 12px !important;
        }
        select {
          width: calc(100% - 20px);
          padding: 10px;
          margin: 0 10px;
          border-radius: 5px;
          border: none;
          outline: none !important;
          background: $chatColor4;
        }
      }
      .button-container {
        display: flex;
        justify-content: space-around;
        button{
          margin:10px;
          background: none;
          color: $chatColor1;
          border: 0;
          font-size: 10px;
          text-transform: uppercase;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .body {
      max-height: calc(100vh - 350px);
      transition: max-height 500ms;
    }
    .chat-options {
      &.show {
        & + .body {
          max-height: calc(100vh - 490px);
        }
      }
    }
    .body {
      padding: 10px;
      overflow-y: scroll;

      * {
        font-size: 16px;
        border-radius: 5px;
      }

      .init {
        padding: 10px;
        background: $chatColor4;
        text-align: center;
      }

      .question,.answer {
        width: 100%;
        .wrapper{
          width:unset;
          margin: unset;
        }
      }
      .question {
        .wrapper {
          display: flex;
          justify-content: flex-end;
          .message {
            border-radius: 15px 0 0 15px;
            background: $chatColor7;
          }
        }
      }

      .answer {
        .wrapper {
          .message {
            border-radius: 0 15px 15px 0;
            background: $chatColor5;
          }
        }
      }

      .message {
        margin-top:5px;
        padding: 10px 15px;
        width: fit-content;
        font-size: 14px;
      }

      .extras {

      }

      .time {
        padding: 3px 6px;
        font-size: 10px;
        color: $chatColor7;
      }

    }

    .footer {
      padding:10px;
      display: flex;
      textarea {
        width: calc(100% - 40px);
        resize: vertical;
        min-height: 120px;
        padding: 5px 10px;
        margin: -1px 0;
        outline: none!important;
        border: 1px solid #4d00f4;
        border-radius: 5px 0 0 5px;
        &.blink-error {
          background-color: $chatColor5;
        }
        &.blink-success {
          background-color: $chatColor6;
        }
      }

      .buttons {
        width: 40px;
        display: flex;
        align-content: space-around;
        flex-wrap: wrap;
        justify-content: center;
        border-left: 1px solid $chatColor1;
        svg {
          padding: 5px;
          margin:0;
          background: $chatColor2;
          color: $chatColor1;
          width:30px;
          height: calc(33% - 7px);
          &.clear{
            width: 18px;
            padding: 4px 11px;
            border-top-right-radius: 5px;
          }
          &.listen{
            width: 24px;
            padding: 4px 8px;
            &._ing {
              width: 15px;
              padding: 4px 12px 4px 13px;
              background: lighten($chatColor2,20%);
            }
          }
          &.putQuestion{
            width: 18px;
            padding: 4px 11px;
            border-bottom-right-radius: 5px;
          }
          &:hover {
            background: lighten($chatColor2,20%);
          }
        }
      }
    }

    $headerHeight: 56px;
    & > .message {
      &.error {
        box-sizing: border-box;
        position: absolute;
        top: $headerHeight;
        display: flex;
        align-items: center;
        padding: 30px;
        width: 100%;
        height: calc(100% - $headerHeight);
        border-radius: 0 0 5px 5px;
        background: rgba($chatColor1,.95);
        color: $chatColor8;
        p {
          text-align: center;
          font-weight: bold;
          font-size:18px;
        }
        .close-error-message {
          position: absolute;
          top: 0;
          right: 0;
          margin:20px;
          padding: 2px 4px;
          border-radius:50%;
          border: 1px solid $chatColor8;
        }
      }
    }
    .loading-bg {
      opacity: 0;
      transition: opacity 400ms ease-in-out;
      &.show {
        background: rgba(#000,70%);
        position: absolute;
        top: $headerHeight;
        width: 100%;
        height: calc(100% - $headerHeight);
        opacity: 100%!important;
        border-radius: 0 0 5px 5px;
        .loading-ring {
          display: inline-block;
          position: absolute;
          top: calc(50% - 40px);
          left: calc(50% - 40px);
          width: 80px;
          height: 80px;
          div {
            display: block;
            position: absolute;
            width: 64px;
            height: 64px;
            margin: 8px;
            box-sizing: border-box;
            border: 8px solid transparent;
            border-radius: 50%;
            animation: loading-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            border-top-color: #fff;
            &:nth-child(1) {
              animation-delay: -0.45s;
            }
            &:nth-child(2) {
              animation-delay: -0.3s;
            }
            &:nth-child(3) {
              animation-delay: -0.15s;
            }
          }
        }
      }

      @keyframes loading-ring {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }

  .invisible {
    display: none!important;
  }

  /* scrollbar coloring */
  & {
    ::-webkit-scrollbar {
      width: 6px;
      cursor: pointer !important;
    }

    ::-webkit-scrollbar-track {
      background: $chatColor4;
    }

    ::-webkit-scrollbar-thumb {
      background: $chatColor2;
    }

    ::-webkit-scrollbar-thumb:window-inactive {
      background: $chatColor2;
    }
  }
}
